import revive_payload_client_4sVQNw7RlN from "/Users/raulretuerto/dev/cloudfare-workers/nuxt-frontend/.sanremo/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/Users/raulretuerto/dev/cloudfare-workers/nuxt-frontend/.sanremo/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/Users/raulretuerto/dev/cloudfare-workers/nuxt-frontend/.sanremo/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/Users/raulretuerto/dev/cloudfare-workers/nuxt-frontend/.sanremo/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/Users/raulretuerto/dev/cloudfare-workers/nuxt-frontend/.sanremo/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/Users/raulretuerto/dev/cloudfare-workers/nuxt-frontend/.sanremo/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/Users/raulretuerto/dev/cloudfare-workers/nuxt-frontend/.sanremo/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/Users/raulretuerto/dev/cloudfare-workers/nuxt-frontend/.sanremo/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/Users/raulretuerto/dev/cloudfare-workers/nuxt-frontend/.sanremo/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/Users/raulretuerto/dev/cloudfare-workers/nuxt-frontend/.sanremo/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_KlVwwuJRCL from "/Users/raulretuerto/dev/cloudfare-workers/nuxt-frontend/.sanremo/node_modules/@storyblok/nuxt/dist/runtime/plugin.mjs";
import plugin_ghbUAjaD3n from "/Users/raulretuerto/dev/cloudfare-workers/nuxt-frontend/.sanremo/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import switch_locale_path_ssr_5csfIgkrBP from "/Users/raulretuerto/dev/cloudfare-workers/nuxt-frontend/.sanremo/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_sq1MuCrqbC from "/Users/raulretuerto/dev/cloudfare-workers/nuxt-frontend/.sanremo/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_client_OVoKJro5pc from "/Users/raulretuerto/dev/cloudfare-workers/nuxt-frontend/.sanremo/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import _00_sentry_client_3cjTwoKJ1L from "/Users/raulretuerto/dev/cloudfare-workers/nuxt-frontend/.sanremo/plugins/00.sentry.client.ts";
import _01_i18n_C0VqlaT7Ms from "/Users/raulretuerto/dev/cloudfare-workers/nuxt-frontend/.sanremo/plugins/01.i18n.ts";
import _02_cookieconsent_client_ewjX1AE2ia from "/Users/raulretuerto/dev/cloudfare-workers/nuxt-frontend/.sanremo/plugins/02.cookieconsent.client.ts";
import _03_user_client_0zxiGtHCSp from "/Users/raulretuerto/dev/cloudfare-workers/nuxt-frontend/.sanremo/plugins/03.user.client.ts";
import _04_loading_client_CA8o39wVJc from "/Users/raulretuerto/dev/cloudfare-workers/nuxt-frontend/.sanremo/plugins/04.loading.client.ts";
import _05_doofinder_client_vdSfLWbPY7 from "/Users/raulretuerto/dev/cloudfare-workers/nuxt-frontend/.sanremo/plugins/05.doofinder.client.ts";
import _06_scrollToTop_client_FAPYKWFYRF from "/Users/raulretuerto/dev/cloudfare-workers/nuxt-frontend/.sanremo/plugins/06.scrollToTop.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_KlVwwuJRCL,
  plugin_ghbUAjaD3n,
  switch_locale_path_ssr_5csfIgkrBP,
  i18n_sq1MuCrqbC,
  plugin_client_OVoKJro5pc,
  _00_sentry_client_3cjTwoKJ1L,
  _01_i18n_C0VqlaT7Ms,
  _02_cookieconsent_client_ewjX1AE2ia,
  _03_user_client_0zxiGtHCSp,
  _04_loading_client_CA8o39wVJc,
  _05_doofinder_client_vdSfLWbPY7,
  _06_scrollToTop_client_FAPYKWFYRF
]